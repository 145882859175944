import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "@/repositories/ErrorHandler";
import { countryApi } from "./CountryApi";
import type { CityResponse } from "../../models/country/CityResponse";
import type { Country } from "../../models/country/Country";

export function useCountry() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getAllCountries = async (): Promise<Country[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await countryApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getCity = async (code: string): Promise<CityResponse[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await countryApi.getCity(authHeaders, code);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getAllCountries, getCity };
}
