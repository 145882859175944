import { Currency } from "@/models/currency/Currency";
import { useErrorHandler } from "@/repositories/ErrorHandler";
import { currencyApi } from "./CurrencyApi";

export function useCurrency() {
  const { handleError } = useErrorHandler();

  const getCurrency = async (currencyIso: string): Promise<Currency> => {
    try {
      const currecies = await currencyApi.getAllMock();
      const currency = currecies.find((c) => c.name === currencyIso);
      return currency ?? ({ name: "NOK", value: 1 } as Currency);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getAllCurrencies = async (): Promise<Currency[]> => {
    try {
      return await currencyApi.getAllMock();
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getCurrency, getAllCurrencies };
}
