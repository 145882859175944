import { NIL as emptyUuid } from "uuid";
import { SupplierCurrency as Currency } from "./SupplierCurrency";
import { SupplierContact as Contact } from "./SupplierContact";
import { SupplierDocument } from "./SupplierDocument";
import { PurchasePermit } from "./PurchasePermit";
import { Incoterms } from "./Incoterms";
import { SupplierAddress } from "./SupplierAddress";

export class Supplier {
  id = emptyUuid;
  companyId = "";
  name = "";
  phoneNumber = "";
  email = "";
  companyAddress: SupplierAddress = new SupplierAddress();
  returnAddress: SupplierAddress = new SupplierAddress();
  invoiceAddress: SupplierAddress = new SupplierAddress();
  currency: Currency | null = null;
  documentLanguage = "";
  documents: SupplierDocument[] = [];
  countryIso = "";
  contacts: Contact[] = [];
  clientIds: string[] = [];
  freightMethodId = "";
  purchasePermit: PurchasePermit = PurchasePermit.Open;
  incoterms: Incoterms | null = null;
  defaultPaymentTermId = "";
  registeredBy = "";
  businessNumber = "";
  registeredDate = "";
  supplierNumber = "";
  fixedShipping = 0;
  freeShippingFrom = 0;
  estimatedDeliveryDays = 0;
}
