<template>
  <FloatLabel variant="on">
    <InputText
      id="business-number"
      type="text"
      v-model="selectedBusinessNumber"
      data-testid="supplier-business-number"
      class="w-full"
      maxlength="8000"
      v-tooltip.focus.bottom="{
        value: t('placeholder.type', { property: t('supplier.business-number').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />
    <label>{{ t("supplier.business-number") }}</label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed } from "vue";

const props = defineProps<{
  businessNumber: string;
}>();

const emit = defineEmits<{
  (e: "update:businessNumber", value: string | null): void;
}>();

const selectedBusinessNumber = computed<string>({
  get: () => {
    return props.businessNumber ?? "";
  },
  set: (value) => {
    emit("update:businessNumber", value);
  },
});

const { t } = useI18n();
</script>
