import { createRouter, createWebHistory } from "vue-router";

import { mainRoutes, supplierRoutes } from "@/router/routes";
import { registerGuard } from "@cumulus/event-bus";

const routes = [...mainRoutes, ...supplierRoutes];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes,
  linkActiveClass: "active",
});

registerGuard(router);

export default router;
