<template>
  <SupplierToolbar
    @onToggleFilterSidebar="onToggleFilterSidebar"
    :showSidebar="showSidebar"
    v-model:filters="filtersComputed"
  />
  <div class="c-supplier-search">
    <div class="flex">
      <div class="flex-auto c-transition">
        <Card>
          <template #content>
            <Suspense>
              <SupplierList
                :suppliers="suppliers"
                :loading="loading"
                :totalHits="totalHits"
                :page="page"
                :pageSize="pageSize"
                :sortField="sortField"
                :sortOrder="sortOrder"
                @update:sortOrder="onUpdateSortOrder"
                @update:sortField="onUpdateSortField"
                @update:page="onUpdatePage"
                @update:pageSize="onUpdatePageSize"
                @refreshList="onRefreshList"
              />
            </Suspense>
          </template>
        </Card>
      </div>

      <div v-if="isMobile">
        <Drawer v-model:visible="showSidebar" :header="t('supplier.search.filter')" position="right">
          <SupplierFilterSidebar v-model:filters="filtersComputed" />
        </Drawer>
      </div>

      <div
        v-else
        class="overflow-x-hidden min-h-full transition-all duration-300 ease-in-out"
        :class="showSidebar ? 'w-[22rem]' : 'w-0'"
      >
        <Card class="ml-5 h-full">
          <template #content>
            <div class="c-filter-header-container">
              <i class="pi pi-sliders-h"></i>
              <span class="ml-4 font-semibold text-xl">{{ t("supplier.search.filter") }}</span>
            </div>

            <SupplierFilterSidebar v-model:filters="filtersComputed" />
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { SupplierSearchRequest } from "@/repositories/search/model/SupplierSearchRequest";
import SupplierList from "../components/SupplierList.vue";
import { SupplierSearch } from "@/repositories/search/model/SupplierSearch";
import SupplierToolbar from "@/supplier/search/SupplierToolbar.vue";
import { useSearch } from "@/repositories/search/SearchService";
import SupplierFilterSidebar from "./SupplierFilterSidebar.vue";
import { SearchFilters } from "@/repositories/search/model//SearchFilters";
import { useMobile } from "@/utils/MobileService";

const { t } = useI18n();

const { supplierSearch } = useSearch();
const route = useRoute();

const pageSize = ref<number>(50);
const page = ref(1);
const totalHits = ref(0);
const query = ref("");
const loading = ref(false);
const suppliers = ref<SupplierSearch[]>([]);
const filters = ref<SearchFilters>(new SearchFilters());
const showSidebar = ref(true);
const sortField = ref("");
const sortOrder = ref(-1);

const { isMobile } = useMobile();
watch(isMobile, (value) => {
  if (value == true) showSidebar.value = false;
});

const filtersComputed = computed<SearchFilters>({
  get: () => filters.value,
  set: async (value) => {
    await onFilterUpdate(value);
  },
});

const onFilterUpdate = async (value: SearchFilters) => {
  filters.value = value;
  page.value = 1;
  await search();
};

watch(
  () => route.query.search,
  async (newQuery) => {
    if (newQuery !== undefined) {
      query.value = decodeURIComponent((newQuery as string) ?? "");
      await search();
    }
  }
);

onMounted(async () => {
  query.value = decodeURIComponent((route.query.search as string) ?? "");
  await search();
});

const onToggleFilterSidebar = () => {
  showSidebar.value = !showSidebar.value;
};

const search = async () => {
  try {
    const request = new SupplierSearchRequest(query.value);

    request.page = page.value;
    request.pageSize = pageSize.value;
    request.filters = filters.value;
    if (sortField.value === "") {
      sortOrder.value = -1;
    }

    request.sortBy = sortField.value;
    request.sortOrder = sortOrder.value === 1 ? "asc" : "desc";

    const response = await supplierSearch(request);
    suppliers.value = response.suppliers;
    totalHits.value = response.totalHits;
  } finally {
    loading.value = false;
  }
};

const onUpdateSortOrder = (value: number) => {
  sortOrder.value = value;
  loading.value = true;
};

const onUpdateSortField = (value: string) => {
  sortField.value = value;
  loading.value = true;
};

const onRefreshList = async () => {
  loading.value = true;
  await search();
};

const onUpdatePage = (value: number) => {
  page.value = value;
  loading.value = true;
  search();
};

const onUpdatePageSize = (value: number) => {
  pageSize.value = value;
  loading.value = true;
  search();
};
</script>

<style scoped>
.c-supplier-search {
  margin: var(--default-content-margin);
  @media (min-width: 992px) {
    margin-bottom: 2rem;
  }
}
</style>
