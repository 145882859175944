import { Supplier } from "@/repositories/supplier/model/Supplier";
import { SupplierAddress } from "@/repositories/supplier/model/SupplierAddress";
import { Ref } from "vue";

export function supplierAddressFunctions(supplier: Ref<Supplier>) {
  const setCompanyAddress = (value: SupplierAddress) => {
    const address = structuredClone(value);
    supplier.value.companyAddress = address;

    if (!addressComplete(supplier.value.companyAddress)) return;

    if (addressEmpty(supplier.value.returnAddress)) {
      const address = structuredClone(value);
      setReturnAddress(address);
    }

    if (addressEmpty(supplier.value.invoiceAddress)) {
      const address = structuredClone(value);
      setInvoiceAddress(address);
    }
  };

  const setReturnAddress = (value: SupplierAddress) => {
    const address = structuredClone(value);
    supplier.value.returnAddress = address;

    if (!addressComplete(supplier.value.returnAddress)) return;

    if (addressEmpty(supplier.value.companyAddress)) {
      const address = structuredClone(value);
      setCompanyAddress(address);
    }

    if (addressEmpty(supplier.value.invoiceAddress)) {
      const address = structuredClone(value);
      setInvoiceAddress(address);
    }
  };

  const setInvoiceAddress = (value: SupplierAddress) => {
    const address = structuredClone(value);
    supplier.value.invoiceAddress = address;

    if (!addressComplete(supplier.value.invoiceAddress)) return;

    if (addressEmpty(supplier.value.companyAddress)) {
      const address = structuredClone(value);
      setCompanyAddress(address);
    }

    if (addressEmpty(supplier.value.returnAddress)) {
      const address = structuredClone(value);
      setReturnAddress(address);
    }
  };

  const addressComplete = (address: SupplierAddress) => {
    return (
      address.addressLines.length > 0 &&
      address.postalCode.length > 0 &&
      address.city.length > 0 &&
      address.countryIso.length > 0
    );
  };

  const addressEmpty = (address: SupplierAddress) => {
    return (
      (address.addressLines.length === 0 && address.postalCode.length === 0 && address.city.length === 0) ||
      address.countryIso.length === 0
    );
  };

  return {
    setCompanyAddress,
    setReturnAddress,
    setInvoiceAddress,
  };
}
