import { Component } from "vue";
import { RouteRecordRaw } from "vue-router";

export const supplierRoutes = [
  {
    name: "supplier-search",
    path: "/supplier/search/:search?",
    component: (): Component => import("@/supplier/search/SupplierSearch.vue"),
    meta: {
      authRequired: true,
      title: "supplier.search-supplier",
    },
  },
  {
    name: "supplier-add",
    path: "/supplier/add",
    component: (): Component => import("@/supplier/add/SupplierAdd.vue"),
    meta: {
      authRequired: true,
      title: "supplier.add-supplier",
    },
  },
  {
    name: "supplier-edit",
    path: "/supplier/edit/:id",
    component: (): Component => import("@/supplier/edit/SupplierEdit.vue"),
    meta: {
      authRequired: true,
      title: "supplier.edit-supplier",
    },
  },
];

export const mainRoutes: RouteRecordRaw[] =
  import.meta.env.VITE_APP_STANDALONE === "true" && !import.meta.env.VITE_APP_TESTS
    ? [
        {
          name: "Pages",
          path: "/",
          component: (): Component => import("./Routes.vue"),
        },
        {
          name: "oauth2-redirect",
          path: "/oauth2-redirect",
          component: (): Component => import("../components/OAuth2Redirect.vue"),
        },
        {
          name: "oauth2-singout",
          path: "/oauth2-singout",
          component: (): Component => import("../components/OAuth2Signout.vue"),
        },
        {
          name: "Failed",
          path: "/failed",
          component: (): Component => import("../components/Failed.vue"),
        },
      ]
    : [];
