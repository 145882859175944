import { SupplierAddress } from "@/repositories/supplier/model/SupplierAddress";
export class SupplierSearch {
  id = "";
  registeredDate = "";
  companyId = "";
  businessNumber = "";
  companyAddress = new SupplierAddress();
  email = "";
  name = "";
  phoneNumber = "";
  supplierNumber = "";
}
