import { useAuth } from "@cumulus/event-bus";
import type { PaymentTerm } from "./model/PaymentTerm";
import { paymentTermApi } from "./PaymentTermApi";
import { useErrorHandler } from "../ErrorHandler";

export function usePaymentTerm() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getPaymentTermsForSupplier = async (): Promise<PaymentTerm[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await paymentTermApi.getPaymentTermsForSuppliers(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getPaymentTermsForSupplier };
}
