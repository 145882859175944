import { useAuth } from "@cumulus/event-bus";
import { documentApi } from "./DocumentApi";
import { SupplierDocument } from "@/repositories/supplier/model/SupplierDocument";
import { useErrorHandler } from "@/repositories/ErrorHandler";

export function useDocumentService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const uploadDocument = async (formData: FormData): Promise<SupplierDocument> => {
    try {
      const authHeaders = await getAuthHeaders();
      const newDocument = new SupplierDocument();
      const document = await documentApi.upload(authHeaders, formData);
      newDocument.documentUrl = document.fileName;
      newDocument.documentFileType = document.documentFileType;
      newDocument.heading = document.originalFileName;
      return newDocument;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getDocumentUrl = async (fileName: string): Promise<string> => {
    try {
      const authHeaders = await getAuthHeaders();

      const document = await documentApi.getDocumentUrl(authHeaders, fileName);
      return document;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    uploadDocument,
    getDocumentUrl,
  };
}
