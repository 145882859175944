import { useAuth } from "@cumulus/event-bus";
import { searchApi } from "./SearchApi";
import { SupplierSearchRequest } from "./model/SupplierSearchRequest";
import { SupplierSearchResponse } from "./model/SupplierSearchResponse";
import { useErrorHandler } from "../ErrorHandler";

export function useSearch() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const supplierSearch = async (request: SupplierSearchRequest): Promise<SupplierSearchResponse> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await searchApi.supplierSearch(authHeaders, request);
    } catch (error) {
      await handleError(error);
      return new SupplierSearchResponse();
    }
  };

  return { supplierSearch };
}
