<template>
  <FloatLabel variant="on">
    <InputNumber
      v-model="fixedShippingComputed"
      inputId="fixed-shipping"
      data-testid="fixed-shipping"
      mode="decimal"
      :min="0"
      :maxFractionDigits="5"
      class="w-full"
      v-tooltip.bottom="{
        value: t('placeholder.type', { property: t('supplier.freight-method').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />

    <label for="fixed-shipping">
      {{ t("supplier.fixed-shipping") }}
    </label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  fixedShipping: number;
}>();

const emit = defineEmits<{
  (e: "update:fixedShipping", value: number | null): void;
}>();

const fixedShippingComputed = computed<number>({
  get: () => {
    return props.fixedShipping ?? 0;
  },
  set: (value) => {
    if (value !== undefined && value !== null) {
      emit("update:fixedShipping", value);
    }
  },
});
</script>
