<template>
  <div class="flex flex-wrap mt-3 pl-4">
    <div class="min-w-full lg:min-w-0 lg:w-2/12 xl:w-4/12 content-center">
      <Button
        id="btn-cancel"
        icon="pi pi-arrow-left"
        data-testid="btn-cancel"
        rounded
        size="small"
        variant="text"
        @click="routeToSupplierList"
      />
      <div class="inline-block ml-4 text-lg">
        <span class="text-slate-800 dark:text-white">{{ supplier.supplierNumber }} - {{ supplier.name }}</span>
      </div>
    </div>

    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-4 lg:mb-0 text-center">
      <SelectButton
        v-model="selectedTab"
        class="c-tabs"
        :allowEmpty="false"
        :options="tabOptions"
        :pt="{ label: 'w-full' }"
      >
        <template #option="slotProps">
          <div class="truncate w-20 xl:w-full">
            {{ t(`supplier.tabs.${slotProps.option}`) }}
          </div>
        </template>
      </SelectButton>
    </div>

    <div
      id="teleport-product-toolbar"
      class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-2 lg:mb-0 text-center lg:text-right lg:pr-5"
    ></div>
  </div>

  <template v-if="selectedTab === 'overview'">
    <teleport to="#teleport-product-toolbar">
      <!-- Add toolbar buttons -->
    </teleport>

    <div class="c-page-content mt-1 ml-5 pr-1">
      <div class="w-full mt-6 text-center text-xl">Under construction...</div>
    </div>
  </template>

  <template v-if="selectedTab === 'details'">
    <SupplierInformation />
  </template>

  <template v-if="selectedTab === 'prodicts'">
    <teleport to="#teleport-product-toolbar">
      <!-- Add toolbar buttons -->
    </teleport>

    <div class="c-page-content mt-1 ml-5 pr-1">
      <div class="w-full mt-6 text-center text-xl">Under construction...</div>
    </div>
  </template>

  <template v-if="selectedTab === 'purchase'">
    <teleport to="#teleport-product-toolbar">
      <!-- Add toolbar buttons -->
    </teleport>

    <div class="c-page-content mt-1 ml-5 pr-1">
      <div class="w-full mt-6 text-center text-xl">Under construction...</div>
    </div>
  </template>

  <template v-if="selectedTab === 'goodreceived'">
    <teleport to="#teleport-product-toolbar">
      <!-- Add toolbar buttons -->
    </teleport>

    <div class="c-page-content mt-1 ml-5 pr-1">
      <div class="w-full mt-6 text-center text-xl">Under construction...</div>
    </div>
  </template>
</template>

<script setup lang="ts">
import SupplierInformation from "@/supplier/edit/SupplierInformation.vue";
import { onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useSupplier } from "@/repositories/supplier/SupplierService";

import { Supplier } from "@/repositories/supplier/model/Supplier";
const { getSupplier } = useSupplier();

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const supplier = ref<Supplier>(new Supplier());

const confirmedDiscard = ref(false);

const routeToSupplierList = () => {
  confirmedDiscard.value = true;
  if (window.history.state.back === null || window.history.state.back.indexOf("/supplier/search") === -1) {
    router.push({ name: "supplier-search", query: { search: "" } });
  } else {
    const url = new URL(window.location.origin + window.history.state.back);
    url.searchParams.set("focusResult", supplier.value.id);
    router.push({ path: window.history.state.back, query: paramsToObject(url.searchParams) });
  }
};

const fetchSupplierData = async () => {
  const supplierId = route.params.id as string;
  supplier.value = await getSupplier(supplierId);
};

const paramsToObject = (entries: URLSearchParams) => {
  const result = {} as Record<string, string>;
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

const selectedTab = ref("");
const tabOptions = ref(["overview", "details", "products", "purchase", "goodreceived"]);

const currentUrlTab = ref("");

onMounted(async () => {
  currentUrlTab.value = (route.query.tab as string) ?? "details";
  selectTab(currentUrlTab.value);

  await fetchSupplierData();
});

const selectTab = (tabName: string) => {
  switch (tabName) {
    case "overview":
    case "details":
    case "products":
    case "purchase":
    case "goodreceived":
      break;
    default:
      tabName = "details";
      break;
  }

  selectedTab.value = tabName;
};

watch(
  () => selectedTab.value,
  (tab: string) => {
    if (tab === currentUrlTab.value) return;

    //Load all data after changes
    router.replace({
      query: {
        ...router.currentRoute.value.query,
        tab: tab,
      },
    });
  },
);
</script>

<style scoped lang="scss">
:deep(.c-tabs) .p-togglebutton {
  background: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
}

:deep(.c-tabs) .p-togglebutton.p-togglebutton-checked {
  background: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #4d7694;
  border-radius: 0;
}

:deep(.c-tabs) .p-togglebutton.p-togglebutton-checked::before {
  background-color: transparent;
  box-shadow: none;
}
</style>
