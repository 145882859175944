<template>
  <FloatLabel variant="on">
    <Select
      id="supplier-freight-method"
      :options="freightMethods"
      v-model="selectedFreightMethodId"
      optionLabel="name"
      optionValue="id"
      class="w-full"
      :invalid="val.selectedFreightMethodId.$error"
      data-testid="supplier-freight-method"
      pt:list:data-testid="supplier-freight-method-list"
      v-tooltip.bottom="{
        value: t('placeholder.type', { property: t('supplier.freight-method').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />

    <label for="supplier-freight-method">
      {{ t(`supplier.freight-method`) }}
    </label>
  </FloatLabel>
  <small class="p-error" v-if="val.selectedFreightMethodId.$error" data-testid="freight-method-error">
    {{ val.selectedFreightMethodId.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useFreightMethod } from "@/repositories/freight-method/FreightMethodService";
import { FreightMethod } from "@/repositories/freight-method/model/FreightMethod";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";

const { getFreightMethodsForSupplier } = useFreightMethod();

const props = defineProps<{
  freightMethodId: string;
}>();

const emit = defineEmits<{
  (e: "update:freightMethodId", value: string): void;
}>();

const { t } = useI18n();
const freightMethods = ref<FreightMethod[]>([]);

const selectedFreightMethodId = computed<string>({
  get: () => {
    return props.freightMethodId ?? "";
  },
  set: (value) => {
    emit("update:freightMethodId", value);
  },
});

const fetchFreightMethods = async () => {
  freightMethods.value = await getFreightMethodsForSupplier();
};
const rules = {
  selectedFreightMethodId: {
    required,
  },
};

const val = useVuelidate(rules, { selectedFreightMethodId: selectedFreightMethodId });

onMounted(fetchFreightMethods);
</script>
