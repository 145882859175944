<template>
  <FloatLabel variant="on">
    <AutoComplete
      id="supplier-country"
      v-model="selectedCountry"
      :suggestions="filteredCountries"
      @complete="searchCountry($event)"
      @change="onCountryChange($event as unknown as AutoCompleteChangeEvent)"
      optionLabel="name"
      class="w-full"
      :invalid="val.countryIso.$error"
      data-testid="supplier-country"
      forceSelection
      dropdown
      pt:list:data-testid="supplier-country-list"
      v-tooltip.bottom="{
        value: t('placeholder.select', { property: t('supplier.country').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    >
      <template #option="slotProps">
        <div class="country-item">
          <img
            src="@/assets/flag_placeholder.png"
            :class="'flag flag-' + slotProps.option.iso.toLowerCase()"
            width="18"
          />
          <div class="ml-2">{{ slotProps.option.name }}</div>
        </div>
      </template>
    </AutoComplete>
    <label for="supplier-country">
      {{ t(`supplier.country`) }}
    </label>
  </FloatLabel>
  <small class="p-error" v-if="val.countryIso.$error" data-testid="supplier-country-error">
    {{ val.countryIso.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import type { Country } from "@/models/country/Country";
import { type AutoCompleteChangeEvent, type AutoCompleteCompleteEvent } from "primevue/autocomplete";

const props = defineProps<{
  countryIso: string;
  countryOptions: Country[];
}>();

const emit = defineEmits<{
  (e: "update:countryIso", value: string): void;
}>();

const { t } = useI18n();
const filteredCountries = ref<Country[]>([]);
const selectedCountry = ref<Country | null>(null);

const searchCountry = (event: AutoCompleteCompleteEvent) => {
  if (!event.query.trim().length) {
    filteredCountries.value = [...props.countryOptions];
  } else {
    filteredCountries.value = props.countryOptions.filter((country) => {
      return country.name.toLowerCase().startsWith(event.query.toLowerCase());
    });
  }
};

const onCountryChange = (event: AutoCompleteChangeEvent) => {
  if (event.value) {
    const country = props.countryOptions.find((country) => country.iso === event.value.iso);
    country ? emit("update:countryIso", country.iso) : emit("update:countryIso", "");
  } else {
    emit("update:countryIso", "");
  }
};

watch(
  () => [props.countryIso, props.countryOptions],
  () => {
    const country = props.countryOptions.find((country) => country.iso === props.countryIso);
    selectedCountry.value = country ?? null;
  },
);

const rules = {
  countryIso: {
    required,
  },
};

const val = useValidate(rules, props);
</script>

<style lang="scss" scoped>
.country-item {
  display: flex;
  img {
    margin-right: 0.5rem;
  }
}
</style>
