<template>
  <FloatLabel variant="on">
    <Select
      id="supplier-currency"
      :options="currencies"
      optionLabel="name"
      data-testid="supplier-currency"
      v-model="selectedCurrency"
      class="w-full"
      :invalid="val.currency.$error"
      pt:list:data-testid="supplier-currency-list"
      v-tooltip.bottom="{
        value: t('placeholder.select', { property: t('supplier.currency').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />

    <label for="supplier-currency">
      {{ t(`supplier.currency`) }}
    </label>
  </FloatLabel>
  <small id="supplier-currency-help" data-testid="supplier-currency-error" class="p-error" v-if="val.$error">{{
    val.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { useCurrency } from "@/api/currency/CurrencyService";
import type { Currency } from "@/models/currency/Currency";
import useVuelidate from "@vuelidate/core";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";

const { t } = useI18n();

const props = defineProps<{
  currency: Currency | null;
}>();

const emit = defineEmits<{
  (e: "update:currency", value: Currency): void;
}>();

const selectedCurrency = computed<Currency>({
  get: () => {
    return props.currency ?? ({} as Currency);
  },
  set: (value) => {
    emit("update:currency", value);
  },
});

const { getAllCurrencies } = useCurrency();
const currencies = ref<Currency[]>([]);

const fetchCurrencies = async () => {
  currencies.value = await getAllCurrencies();
};
onMounted(fetchCurrencies);

const rules = {
  currency: {
    required,
  },
};

const val = useVuelidate(rules, props);
</script>
