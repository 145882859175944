import { useAuth } from "@cumulus/event-bus";
import { FreightMethod } from "./model/FreightMethod";
import { freightMethodApi } from "./FreightMethodApi";
import { useErrorHandler } from "../ErrorHandler";

export function useFreightMethod() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getFreightMethodsForSupplier = async (): Promise<FreightMethod[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await freightMethodApi.getFreightMethodsForSupplier(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getFreightMethodsForSupplier };
}
