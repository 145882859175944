<template>
  <FloatLabel variant="on">
    <Select
      id="supplier-status"
      v-model="supplierStatusComputed"
      :options="options"
      optionLabel="label"
      optionValue="value"
      class="w-full"
      data-testid="supplier-purchase-permit"
      pt:list:data-testid="supplier-purchase-permit-list"
    />
    <label for="supplier-status">
      {{ t("supplier.supplier-status") }}
    </label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { ref, computed, WritableComputedRef, onMounted } from "vue";
import { PurchasePermit } from "@/repositories/supplier/model/PurchasePermit";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  purchasePermit: PurchasePermit;
}>();

const emit = defineEmits<{
  (e: "update:purchasePermit", value: string): void;
}>();

const { t } = useI18n();

const options = ref<Record<string, string>[]>([]);

const fetchSupplierStatus = () => {
  for (const state in PurchasePermit) {
    options.value.push({
      label: t(`supplier.status.${state.toString().toLowerCase()}`),
      value: state,
    });
  }
};

onMounted(fetchSupplierStatus);

const supplierStatusComputed: WritableComputedRef<PurchasePermit> = computed({
  get: () => {
    return props.purchasePermit ?? PurchasePermit.Open;
  },
  set: (value) => {
    emit("update:purchasePermit", value);
  },
});
</script>
