import { useAuth } from "@cumulus/event-bus";
import { Supplier } from "./model/Supplier";
import { supplierApi } from "./SupplierApi";
import { useErrorHandler } from "../ErrorHandler";

export function useSupplier() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getSupplier = async (id: string): Promise<Supplier> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await supplierApi.get(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const createSupplier = async (supplier: Supplier): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await supplierApi.create(authHeaders, supplier);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateSupplier = async (supplier: Supplier): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await supplierApi.update(authHeaders, supplier);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const deleteSupplier = async (id: string): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await supplierApi.delete(authHeaders, id);
    } catch (error) {
      await handleError(error);
    }
  };

  const getSupplierNumberInUse = async (supplierNumber: string): Promise<boolean> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await supplierApi.getSupplierNumberInUse(authHeaders, supplierNumber);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getSupplier, createSupplier, updateSupplier, deleteSupplier, getSupplierNumberInUse };
}
