<template>
  <h4 class="text-center font-semibold">{{ t("supplier.search.supplier-state") }}</h4>
  <div
    v-for="(value, index) in allStatusComputed"
    :key="index"
    class="flex justify-content-between relative align-items-center p-2 border-round-md mt-1"
    :class="{
      'bg-[#E7F0F7] dark:bg-slate-800': index % 2 === 0,
      'bg-[#F7FAFC] dark:bg-slate-600': index % 2 === 1,
    }"
  >
    <div class="centered-child">
      <label class="font-light" :for="'supplier-status-' + value.type.toLowerCase()">{{
        t(`supplier.status.${value.type.toLowerCase()}`)
      }}</label>
    </div>
    <div class="ml-auto">
      <Checkbox
        v-model="statusesComputed"
        :value="value.type"
        :inputId="'supplier-status-' + value.type.toLowerCase()"
        :data-testid="'supplier-status-' + value.type.toLowerCase()"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { PurchasePermit } from "@/repositories/supplier/model/PurchasePermit";
import { SearchFilters } from "@/repositories/search/model/SearchFilters";

const { t } = useI18n();

const props = defineProps<{
  filters: SearchFilters;
}>();

const emit = defineEmits<{
  (event: "update:filters", value: SearchFilters): void;
}>();

const statusesComputed = computed<PurchasePermit[]>({
  get: () => props.filters.supplierStatus,
  set: (value) => {
    const filters = { ...props.filters, supplierStatus: value };
    emit("update:filters", filters);
  },
});

const allStatusComputed = computed(() => {
  const allTypes = Object.values(PurchasePermit);
  return allTypes.map((type, index) => {
    return {
      type: type,
      index: index,
    };
  });
});
</script>
