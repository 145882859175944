{
  "validations": {
    "required": "Value is required",
    "email": "Must be a valid e-mail address",
    "min-length": "Minimum length is {min}",
    "exists": "{property} already exists",
    "supplier-number-already-in-use": "Supplier number is already in use",
    "start-number-must-be-greater-than-last-used": "Start number must be greater than last used supplier number"
  },
  "placeholder": {
    "select": "Select {property}",
    "type": "Type {property}"
  },
  "common": {
    "created": "Created",
    "add": "Add",
    "update": "Update",
    "save": "Save",
    "cancel": "Cancel",
    "search": "Search",
    "delete": "Delete",
    "preview": "Preview",
    "reload": "Reload",
    "error": "Error",
    "column-chooser": "Column chooser",
    "reset": "Reset columns",
    "current-page-template": "Showing {first} to {last} of {totalRecords} suppliers",
    "unsaved-changes-header": "Unsaved changes",
    "unsaved-changes-text": "You have unsaved changes. Save your changes or Discard for leaving without saving your changes. Choose Cancel to continue editing",
    "discard": "Discard",
    "confirm-delete": "Do you want to delete this item?",
    "save-tooltip": "No changes",
    "delete-confirm": "Are you sure you want to delete this document?",
    "delete-confirm-all": "Are you sure you want to delete all documents?",
    "validation-error": {
      "summary": "Validation failed",
      "detail": "Correct all red fields and try again"
    },
    "copy": "Copy",
    "document-language": {
      "NO": "Norwegian",
      "DK": "Danish",
      "FI": "Finnish",
      "SE": "Swedish",
      "DE": "German",
      "IT": "Italian",
      "FR": "French",
      "EN": "English"
    },
    "address": {
      "address-lines": {
        "label": "Address lines",
        "placeholder": "Type in address lines, seperate with enter",
        "error": "At least one line is required"
      },
      "postal-code-city": "Postal code/city",
      "postal-code": "Postal code *",
      "city": "City *",
      "country": "Country"
    },
    "error-detail": {
      "unknown": "Unknown error",
      "server-error": "Server error"
    }
  },
  "supplier": {
    "title": "Supplier",
    "add-supplier": "Add Supplier",
    "edit-supplier": "Edit Supplier",
    "search-supplier": "Search Supplier",
    "registered": "Registered",
    "created": "Created:",
    "load-error": "Failed to load supplier, reason: ",
    "name": "Name",
    "business-number": "Business number",
    "country": "Country",
    "email": "E-mail",
    "phone-number": "Phone number",
    "document-language": "Document language",
    "clients": "Clients",
    "download-link-missing": "Failed to get document, please try again later",
    "upload-started": "Upload started",
    "upload-started-details": "Uploading documents, please wait...",
    "number": {
      "label": "Supplier number *",
      "placeholder": "Auto assign, type to override",
      "is-used": "Supplier number is already in use",
      "is-not-used": "Supplier number is available"
    },
    "header": {
      "add-supplier": "Add supplier",
      "edit-supplier": "Edit supplier",
      "suppliers": "Suppliers",
      "purchase-permit": "Purchase permit",
      "open-supplier": "Open",
      "refresh-supplier-list": "Reload"
    },
    "status": {
      "open": "Open",
      "blocked": "Blocked"
    },
    "supplier-status": "Supplier status",
    "addresses": {
      "label": "Addresses",
      "company-address": "Company address",
      "invoice-address": "Invoice address",
      "return-address": "Return address"
    },
    "contacts": {
      "first-name": "First name",
      "last-name": "Last name",
      "email": "E-mail",
      "phone-number": "Phone number",
      "header": "Contacts",
      "default-contact": "Primary contact",
      "default-contact-selected": "Primary contact selected"
    }, 
    "documents": {
      "header": "Documents",
      "name": "Document name",
      "type": "Document type",
      "title": "Title",
      "description": "Description",
      "no-description": "No description",
      "unnamed-document": "Unnamed Document",
      "no-documents-found": "No document agreements found for this supplier",
      "choose-label": "Add documents",
      "drag-and-drop": "Drag and drop new documents here",
      "upload": "Upload document",
      "manage": "Manage documents",
      "select": "Select document",
      "clear": "Clear documents",
      "supported-formats": "Supported formats: DOC, DOCX, PDF, TXT, RTF (Max size: 10MB)",
      "manage-product-documents": "Manage Product Documents"
    },
    "condition-and-freight": "Conditions and Freight",
    "conditions": "Conditions",
    "currency": "Currency",
    "payment-term": "Payment term",
    "incoterms": "Incoterms",
    "freight-method": "Freight method",
    "freight": "Freight",
    "fixed-shipping": "Fixed shipping",
    "free-shipping": "Free shipping from",
    "estimated-delivery": "Estimated delivery",
    "delete": {
      "confirm-message": "Are you sure you want to delete this supplier?"
    },
    "toast": {
      "reload": "Reloading supplier..",
      "add-success": {
        "summary": "Supplier is added!",
        "detail": "Supplier name: {name}"
      },
      "update-success": {
        "summary": "Supplier is updated!",
        "detail": "Supplier name {name}"
      },
      "delete-success": {
        "summary": "Supplier is deleted!",
        "detail": "Supplier name: {name}"
      },
      "error": {
        "summary": "An error occurred",
        "detail": "An unknown error occured, please try again later"
      },
      "open-error": {
        "open-supplier-failed": "Failed to open supplier",
        "no-supplier-selected": "No supplier selected. Select a supplier and try again."
      }
    },
    "search": {
      "search-label": "Supplier search:",
      "more-filters": "More filters",
      "total-hits": "{totalHits} suppliers found",
      "advanced-btn": "Advanced",
      "select-all": "Select all",
      "delete-selected": "Delete selected",
      "edit-columns": "Customize columns",
      "header-name": "Supplier name",
      "header-phone-number": "Phone number",
      "header-supplier-status": "Supplier status",
      "search-error": "Search failed",
      "empty-list": "No suppliers found.",
      "loading-list": "Loading suppliers, please wait...",
      "current-page": "Showing {first} to {last} of {totalRecords} suppliers",
      "filter": "Filter",
      "show-filter": "Show filter",
      "hide-filter": "Hide filter",
      "clear-filter": "Clear filter",
      "supplier-state": "Supplier status"
    },
    "panel": {
      "close-panels": "Close tabs",
      "open-panels": "Open tabs"
    }
  }
}