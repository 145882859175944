import { Currency } from "@/models/currency/Currency";

class CurrencyApi {
  public getAllMock(): Promise<Currency[]> {
    //TODO: Remove MOCK code and exchange it with a real currency API implementation!
    const promise = new Promise<Currency[]>((resolve) => {
      const response = [
        { name: "NOK", value: 0 },
        { name: "SEK", value: 1 },
        { name: "EUR", value: 2 },
        { name: "AUD", value: 3 },
        { name: "BRL", value: 4 },
        { name: "CNY", value: 5 },
        { name: "EGP", value: 6 },
        { name: "INR", value: 7 },
        { name: "JPY", value: 8 },
        { name: "USD", value: 9 },
        { name: "CAD", value: 10 },
      ] as Currency[];

      resolve(response);
    });

    return promise;
    //TODO: END MOCK code!
  }
}

const currencyApi = new CurrencyApi();
export { currencyApi };
