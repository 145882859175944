<template>
  <FloatLabel variant="on">
    <Select
      v-model="paymentTermComputed"
      :options="paymentTerms"
      optionLabel="name"
      optionValue="id"
      class="w-full"
      :invalid="val.paymentTermId.$error"
      data-testid="default-payment-term"
      pt:list:data-testid="default-payment-term-list"
      v-tooltip.bottom="{
        value: t('placeholder.select', { property: t('supplier.payment-term').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />

    <label for="supplier-document-language">
      {{ t("supplier.payment-term") }}
    </label>
  </FloatLabel>

  <small class="p-error" v-if="val.$error" data-testid="default-payment-term-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { PaymentTerm } from "@/repositories/payment-term/model/PaymentTerm";
import useVuelidate from "@vuelidate/core";
import { required } from "@/locales/i18n-validators";
import { usePaymentTerm } from "@/repositories/payment-term/PaymentTermService";

const { getPaymentTermsForSupplier } = usePaymentTerm();

const props = defineProps<{
  paymentTermId: string;
}>();

const emit = defineEmits<{
  (e: "update:paymentTermId", value: string): void;
}>();

const paymentTermComputed = computed<string>({
  get: () => {
    return props.paymentTermId ?? "";
  },
  set: (value) => {
    emit("update:paymentTermId", value);
  },
});

const paymentTerms = ref<PaymentTerm[]>([]);

const fetchPaymentTerms = async () => {
  paymentTerms.value = await getPaymentTermsForSupplier();
};

onMounted(fetchPaymentTerms);

const rules = {
  paymentTermId: {
    required,
  },
};

const val = useVuelidate(rules, props);
const { t } = useI18n();
</script>
