<template>
  <FloatLabel variant="on">
    <InputNumber
      v-model="estimatedDeliveryDays"
      inputId="supplier-estimated-delivery"
      data-testid="supplier-estimated-delivery"
      mode="decimal"
      :min="0"
      class="w-full"
      v-tooltip.bottom="{
        value: t('placeholder.type', { property: t('supplier.estimated-delivery').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />
    <label for="supplier-estimated-delivery">
      {{ t(`supplier.estimated-delivery`) }}
    </label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const estimatedDeliveryDays = defineModel<number>("estimatedDeliveryDays");
</script>
