import type { Incoterms } from "./model/Incoterms";

class IncotermsRepository {
  public getAll(): Promise<Incoterms[]> {
    const promise = new Promise<Incoterms[]>((resolve) => {
      const response = [
        { code: "EXW", description: "Ex. Works" },
        { code: "FCA", description: "Free carrier" },
        { code: "CPT", description: "Carriage paid to" },
        { code: "CIP", description: "Carriage and incurance paid to" },
        { code: "DAP", description: "Delivered at place" },
        { code: "DPU", description: "Delivered at place unloaded" },
        { code: "DDP", description: "Delivered duty paid" },
        { code: "FAS", description: "Free alongside ship" },
        { code: "FOB", description: "Free on board" },
        { code: "CFR", description: "Cost and freight" },
        { code: "CIF", description: "Cost insurance and freight" },
      ] as Incoterms[];

      resolve(response);
    });
    return promise;
  }
}

const incotermsRepository = new IncotermsRepository();
export { incotermsRepository };
