<template>
  <FloatLabel variant="on">
    <InputText
      :id="`contact-first-name-${props.rowNumber}`"
      :data-testid="`contact-first-name-${props.rowNumber}`"
      v-model="firstNameComputed"
      class="w-full"
      :invalid="val.firstName.$error"
      v-tooltip.focus.bottom="{
        value: t('placeholder.type', { property: t('supplier.contacts.first-name').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />
    <label :for="`contact-first-name-${props.rowNumber}`">{{ t("supplier.contacts.first-name") }}</label>
  </FloatLabel>
  <small class="p-error" v-if="val.firstName.$error" data-testid="`contact-first-name-error-${props.rowNumber}`">
    {{ val.firstName.$errors[0].$message }}
  </small>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { computed } from "vue";
import { minLength } from "@/locales/i18n-validators";
import { helpers } from "@vuelidate/validators";
import { SupplierContact } from "@/repositories/supplier/model/SupplierContact";

const props = defineProps<{
  firstName: string;
  contact: SupplierContact;
  rowNumber: number;
}>();

const emit = defineEmits<{
  (e: "update:firstName", value: string): void;
}>();

const firstNameComputed = computed<string>({
  get: () => {
    return props.firstName ?? "";
  },
  set: (value) => {
    emit("update:firstName", value);
  },
});

const { t } = useI18n();

const validateContacts = () => {
  if (props.contact.firstName !== "") {
    //Valid
    return true;
  }

  if (
    props.contact.firstName === "" &&
    props.contact.lastName === "" &&
    props.contact.email === "" &&
    props.contact.phoneNumber === ""
  ) {
    //Valid
    return true;
  }

  if (props.contact.firstName !== "" || props.contact.lastName !== "") {
    if (props.contact.phoneNumber !== "" || props.contact.email !== "") {
      //Valid
      return true;
    }
  }

  //Not valid
  return false;
};

const rules = {
  firstName: {
    minLength: helpers.withMessage(() => t("validations.min-length"), minLength(2)),
    required: helpers.withMessage(() => t("validations.firstname-required"), validateContacts),
  },
};

const val = useValidate(rules, props);
</script>
