import { AuthHeaders, httpClient } from "@cumulus/http";
import { Supplier } from "./model/Supplier";

class SupplierApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_PRODUCT != "undefined" && import.meta.env.VITE_APP_API_PRODUCT
        ? import.meta.env.VITE_APP_API_PRODUCT + "/suppliers"
        : `${import.meta.env.VITE_APP_API_URL as string}/suppliers`;
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<Supplier> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then(({ data }) => data);
  }

  public async create(authHeaders: AuthHeaders, supplier: Supplier): Promise<void> {
    return await httpClient(this.uri, authHeaders).post("", supplier);
  }

  public async update(authHeaders: AuthHeaders, supplier: Supplier): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("", supplier);
  }

  public async delete(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete(id);
  }

  public async getSupplierNumberInUse(authHeaders: AuthHeaders, supplierNumber: string): Promise<boolean> {
    return await httpClient(this.uri, authHeaders)
      .get(`/numbers/isused/${supplierNumber}`)
      .then(({ data }) => data);
  }
}
const supplierApi = new SupplierApi();
export { supplierApi };
