<template>
  <div class="c-toolbar-wrapper">
    <div class="c-toolbar">
      <div class="c-header-icon-container">
        <PrimeButton class="c-circular-button mr-3" @click="router.push({ name: 'supplier-add' })">
          <i class="pi pi-plus c-success-button c-circular-icon"></i>
          <span class="px-3">{{ t("supplier.header.add-supplier") }}</span>
        </PrimeButton>
      </div>
      <div class="c-header-icon-container">
        <FilterOverview v-model:filters="filtersComputed" />
        <PrimeButton class="c-circular-button" @click="emit('onToggleFilterSidebar')">
          <i class="pi pi-filter c-default-button c-circular-icon"></i>
          <span class="px-3">{{
            showSidebar ? t("supplier.search.hide-filter") : t("supplier.search.show-filter")
          }}</span>
        </PrimeButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { SearchFilters } from "@/repositories/search/model/SearchFilters";
import FilterOverview from "./FilterOverview.vue";

const props = defineProps<{
  showSidebar: boolean;
  filters: SearchFilters;
}>();

const emit = defineEmits<{
  (e: "onToggleFilterSidebar"): void;
  (e: "update:filters", value: SearchFilters): void;
}>();
const router = useRouter();
const { t } = useI18n();

const filtersComputed = computed({
  get() {
    return props.filters;
  },
  set(value: SearchFilters) {
    emit("update:filters", value);
  },
});
</script>
