export const SupplierLinesColumns = {
  RegisteredDate: "registeredDate",
  SupplierNumber: "supplierNumber",
  Name: "name",
  PhoneNumber: "phoneNumber",
  Email: "email",
  AddressLines: "companyAddress.addressLines",
  City: "companyAddress.city",
  CountryIso: "companyAddress.countryIso",
  PurchasePermit: "purchasePermit",
} as const;
