import { PurchasePermit } from "@/repositories/supplier/model/PurchasePermit";
import { SupplierAddress } from "@/repositories/supplier/model/SupplierAddress";
export class SupplierSearch {
  id = "";
  registeredDate = "";
  companyId = "";
  businessNumber = "";
  companyAddress = new SupplierAddress();
  email = "";
  name = "";
  phoneNumber = "";
  supplierNumber = "";
  purchasePermit: PurchasePermit = PurchasePermit.Open;
}
