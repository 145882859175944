<template>
  <FloatLabel variant="on">
    <InputNumber
      v-model="freeFreightComputed"
      inputId="free-shipping"
      data-testid="free-shipping"
      type="number"
      mode="decimal"
      :min="0"
      :maxFractionDigits="5"
      class="w-full"
    />
    <label for="supplier-free-delivery">
      {{ t("supplier.free-shipping") }}
    </label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

const props = defineProps<{
  freeFreight: number;
}>();

const emit = defineEmits<{
  (e: "update:freeFreight", value: number | null): void;
}>();

const freeFreightComputed = computed<number>({
  get: () => {
    return props.freeFreight ?? 0;
  },
  set: (value) => {
    if (value !== undefined && value !== null) {
      emit("update:freeFreight", value);
    }
  },
});
</script>
