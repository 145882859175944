<template>
  <div class="card">
    <ColumnOptionsMenu :label="t('supplier.title')" :itemsList="items"></ColumnOptionsMenu>
    <DataTable
      ref="supplierSearchResult"
      :value="suppliers"
      v-model:expandedRows="expandedRows"
      v-model:selection="selectedSupplier"
      dataKey="id"
      :autoLayout="true"
      :lazy="true"
      :paginator="true"
      responsiveLayout="scroll"
      selectionMode="single"
      @row-dblclick="onRowDblClicked"
      @row-select="onRowSelected"
      :rowClass="addGlobalSearchClass"
      class="c-datatable"
      stripedRows
      :loading="loading"
      :rows="pageSize"
      :rowsPerPageOptions="[50, 100]"
      data-testid="supplier-table"
      :totalRecords="totalHits"
      :currentPageReportTemplate="
        t('common.current-page-template', { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' })
      "
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      @page="onPage"
      tabindex="0"
      scrollable
      scrollHeight="75vh"
      :resizableColumns="true"
      columnResizeMode="fit"
      removableSort
      :sortOrder="sortOrder"
      :sortField="sortField"
      @sort="onSort"
      :reorderable-columns="true"
      @column-reorder="onColumnReorder"
      @column-resize-end="onColumnResizeEnd"
      :key="renderKey as unknown as number"
    >
      <Column
        v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumn[]"
        :field="col.field"
        :header="col.header"
        :key="col.field + '_' + index"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
            class: col.field,
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template #body="{ data, field }">
          <template v-if="col.field === SupplierLinesColumns.RegisteredDate">
            <span>{{ d(data.registeredDate, "short") }}</span>
          </template>
          <template v-else-if="col.field === SupplierLinesColumns.SupplierNumber">
            <span class="c-supplier-number-text" @click="onSupplierNumberClicked(data)">
              {{ data.supplierNumber }}
            </span>
          </template>
          <template v-else-if="col.field === SupplierLinesColumns.Name">
            {{ data.name }}
            <i
              class="pi pi-copy ml-1 c-copy-column-text-btn"
              @click.stop="copyName(data.name)"
              v-tooltip.right="{ value: t('common.copy') }"
              :data-testid="`name-copy-button-${data.id}`"
            />
          </template>
          <template v-else-if="col.field === SupplierLinesColumns.PhoneNumber">
            {{ data.phoneNumber }}
            <i
              class="pi pi-copy ml-1 c-copy-column-text-btn"
              @click.stop="copyPhoneNumber(data.phoneNumber)"
              v-tooltip.right="{ value: t('common.copy') }"
              :data-testid="`phone-number-copy-button-${data.id}`"
            ></i>
          </template>
          <template v-else-if="col.field === SupplierLinesColumns.Email">
            <span>
              {{ data.email }}
            </span>
          </template>
          <template v-else-if="col.field === SupplierLinesColumns.AddressLines">
            {{ joinAddressLines(data.companyAddress.addressLines) }}
          </template>
          <template v-else-if="col.field === SupplierLinesColumns.City">
            {{ data.companyAddress.city }}
          </template>
          <template v-else-if="col.field === SupplierLinesColumns.CountryIso">
            {{ data.companyAddress.countryIso }}
          </template>
          <template v-else>
            {{ data[field] }}
          </template>
        </template>
      </Column>

      <template #empty>
        <div v-if="!loading">{{ t("supplier.search.empty-list") }}</div>
      </template>
      <template #loading>{{ t("supplier.search.loading-list") }}</template>
    </DataTable>
  </div>
  <ColumnChooser
    v-model:visibleDialog="visible"
    v-model:selectedColumns="selectedColumnsComputed"
    :columns="filteredColumns"
    :label="t('common.reset')"
    :onSelectAllChange="onSelectAllChange"
    :selectAll="selectAll"
    @resetColumns="resetColumns"
    @columnSelected="onColumnSelected"
    @columnUnselected="onColumnUnselected"
  />
</template>
<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useToast } from "primevue/usetoast";
import { SupplierSearch } from "@/repositories/search/model/SupplierSearch";
import {
  DataTablePageEvent,
  DataTableRowDoubleClickEvent,
  DataTableRowSelectEvent,
  DataTableSelectAllChangeEvent,
  DataTableSortEvent,
} from "primevue/datatable";
import { NIL as emptyUuid } from "uuid";
import { useI18n } from "vue-i18n";
import { useCumulusToast } from "@cumulus/toast";
import { useAuth } from "@cumulus/event-bus";
import { SupplierLinesColumns } from "@/repositories/search/model/SupplierLinesColumns";
import { ColumnChooser, useTablePreferences, ColumnOptionsMenu } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";

const { getUser } = useAuth();
const { t, d } = useI18n();

const router = useRouter();
const route = useRoute();

const toast = useCumulusToast(useToast());
const supplierSearchResult = ref();
const selectedSupplier = ref<SupplierSearch>(new SupplierSearch());
const expandedRows = ref([]);
const visible = ref(false);

const props = defineProps<{
  suppliers: SupplierSearch[];
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
  sortOrder: number;
  sortField: string;
}>();

const emit = defineEmits<{
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "refreshList"): void;
}>();

const supplierSearchColumns: DataTableColumn[] = [
  { field: "registeredDate", header: t("supplier.registered"), class: "w-1rem", sortable: true },
  { field: "supplierNumber", header: t("supplier.number.label"), class: "text-right w-1rem", sortable: true },
  { field: "name", header: t("supplier.search.header-name"), sortable: true, class: "c-supplier-name" },
  {
    field: "phoneNumber",
    header: t("supplier.search.header-phone-number"),
    sortable: true,
    class: "c-supplier-phone-number",
  },
  { field: "email", header: t("supplier.email"), sortable: true },
  {
    field: "companyAddress.addressLines",
    header: t("supplier.addresses.label"),
    sortable: true,
    class: "c-supplier-addresses",
  },
  {
    field: "companyAddress.city",
    header: t("common.address.city"),
    sortable: true,
    class: "c-supplier-city w-2rem",
  },
  {
    field: "companyAddress.countryIso",
    header: t("common.address.country"),
    sortable: true,
    class: "c-supplier-country w-1rem",
  },
];
const {
  selectedColumnsComputed,
  renderKey,
  onColumnReorder,
  resetColumns,
  orderedColumns,
  onColumnResizeEnd,
  onColumnSelected,
  onColumnUnselected,
} = useTablePreferences("supplierSearch", supplierSearchColumns, null, (await getUser()).getEmployee().id);

const filteredColumns = computed(() => {
  return orderedColumns.value.filter(
    (col) => col.field !== SupplierLinesColumns.SupplierNumber && col.field !== SupplierLinesColumns.Name
  );
});
const items = [
  {
    label: t("common.reload"),
    icon: "pi pi-refresh c-default-button c-circular-icon",
    class: "c-refresh-button",
    command: () => {
      onClickProductRefresh();
    },
  },
  {
    label: t("common.column-chooser"),
    icon: "pi pi-list c-default-button c-circular-icon",
    class: "c-column-chooser",
    command: () => {
      visible.value = true;
    },
  },
];
const onClickProductRefresh = () => {
  emit("refreshList");
};
const selectAll = ref(false);
const onSelectAllChange = (event: DataTableSelectAllChangeEvent) => {
  selectAll.value = event.checked;
  selectedColumnsComputed.value = event.checked
    ? supplierSearchColumns
    : supplierSearchColumns.filter(
        (c) => c.field === SupplierLinesColumns.SupplierNumber || c.field === SupplierLinesColumns.Name
      );
};

watch([() => props.suppliers, () => props.loading], ([suppliers, loading]) => {
  if (loading === false && suppliers.length > 0) {
    nextTick(() => {
      if (route.query?.focusResult) {
        focusSearchResult((route.query?.focusResult as string) ?? "");
      }
    });
  }
});

const addGlobalSearchClass = (data: SupplierSearch) => {
  if (!data?.id) return ["c-search-result-row"];

  return [
    `c-search-result-row c-sr-id-${data?.id}`,
    { "c-search-result-row-selected": selectedSupplier.value?.id === data?.id },
  ];
};

const joinAddressLines = (address: string[]): string => {
  return address.join(", ");
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  openSearchSupplier(event.data.id);
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    focusSearchResult();
    emit("update:sortOrder", -props.sortOrder);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const onRowDblClicked = (event: DataTableRowDoubleClickEvent) => {
  openSearchSupplier(event.data.id);
};

const copyName = (name: string) => {
  navigator.clipboard.writeText(name);
};

const copyPhoneNumber = (phoneNumber: string) => {
  navigator.clipboard.writeText(phoneNumber);
};

const openSearchSupplier = (supplierId: string) => {
  supplierId != null && supplierId !== emptyUuid
    ? router.push({ name: "supplier-edit", params: { id: supplierId } })
    : toast.add({
        severity: "warn",
        summary: t("supplier.toast.open-supplier-failed"),
        detail: t("supplier.search-header.no-supplier-selected"),
      });
};

const focusSearchResult = (id = "") => {
  if (props.suppliers.length > 0 && supplierSearchResult.value) {
    const element = id === "" ? null : supplierSearchResult.value.$el.querySelector(`tr.c-sr-id-${id}`);
    if (element) {
      element.focus();
      return;
    }

    supplierSearchResult.value.$el.querySelector("tbody tr:first-of-type").focus();
  }
};

const onSupplierNumberClicked = (supplier: SupplierSearch) => {
  selectedSupplier.value = supplier;
  openSearchSupplier(supplier.id);
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    focusSearchResult();
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};
</script>
<style scoped lang="scss">
.c-supplier-number-text:hover {
  text-decoration: underline;
}

.p-datatable-selectable-row .pi.pi-copy.c-copy-column-text-btn {
  font-size: 1.25rem;
  visibility: hidden;
}

tr.p-datatable-selectable-row td.c-supplier-phone-number:hover .c-copy-column-text-btn {
  visibility: visible;
}

tr.p-datatable-selectable-row td.c-supplier-name:hover .c-copy-column-text-btn {
  visibility: visible;
}

:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}
</style>
